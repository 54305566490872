.md-tooltip{
  padding: 10px 15px;
  min-width: 130px;
  max-width: 200px;
  height: auto;
  text-align: center;
  border-radius: $border-radius-base;
  background-color: $white-color !important;
  color: $gray !important;
  font-size: $tooltip-font-size;
  font-weight: 400;
  white-space: normal;
  @include shadow-8dp();

  &:after{
    position: absolute;
    bottom: -5px;
    height: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 5px;
    vertical-align: .255em;
    content: "";
  }

  &[x-placement="top"]:after{
    border-top: 5px solid $white-color;
    border-right: 5px solid transparent;
    border-bottom: 0;
    border-left: 5px solid transparent;
  }
  &[x-placement="bottom"]:after{
    top: -5px;
    border-top: 0;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $white-color;
    border-left: 5px solid transparent;
  }
  &[x-placement="left"]:after{
    margin-right: 0;
    right: -5px;
    top: 16px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $white-color;
  }
  &[x-placement="right"]:after{
    margin-left: 0;
    left: -5px;
    top: 16px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid $white-color;
  }
}
