.dropdown-menu{
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.dropdown,
.dropup{
  position: relative;
}
.dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

    .divider {
        background-color: rgba(0, 0, 0, .12);
    }


    li > a{
        font-size: $mdb-dropdown-font-size;
        padding: 10px 1.5rem;
        margin: 0 5px;
        text-transform: none;
        color: $gray-dark !important;
        border-radius: $border-radius-small;
        display: flex;
        font-weight: 400;
        line-height: 1.428571;
        align-items: center;
        white-space: nowrap;
        @include transition($fast-transition-time, $transition-linear);

        &:hover,
        &:focus {
          @include shadow-8dp();

        }
    }

    &.dropdown-with-icons{
        li > a{
            padding: 12px 20px 12px 12px;

            .material-icons{
                vertical-align: middle;
                font-size: 24px;
                position: relative;
                margin-top: -4px;
                top: 1px;
                margin-right: 12px;
                opacity: .5;
            }
        }
    }

    li {
        position: relative;
        a:hover,
        a:focus,
        a:active {
            background-color: $brand-primary !important;
            color: #FFFFFF !important;
        }
    }

    .md-success + & li {
      @include drop-down-button-color($brand-success);
    }
    .md-danger + & li {
      @include drop-down-button-color($brand-danger);
    }
    .md-warning + & li {
      @include drop-down-button-color($brand-warning);
    }
    .md-primary + & li {
      @include drop-down-button-color($brand-primary);
    }
    .md-rose + & li {
      @include drop-down-button-color($brand-rose);
    }
    .md-info + & li {
      @include drop-down-button-color($brand-info);
    }

    .divider{
        margin: 5px 0;
    }

    .navbar &,
    .navbar.navbar-default &{
        li{
            a:hover,
            a:focus,
            a:active {
                background-color: $brand-primary;
                color: #FFFFFF;
                @include shadow-big-color($brand-primary);
            }
        }
    }

}


.navbar-nav > li > .dropdown-menu,
.dropdown .dropdown-menu,
.dropup .dropdown-menu,
.dropdown-menu.bootstrap-datetimepicker-widget{
    transform: scale(0);
    transform-origin: 0 0;
    display: block;
    @include opacity(0);
    will-change: transform, opacity;
    transition: transform $md-menu-expand-duration $md-animation-curve-default,
                opacity $md-menu-fade-duration $md-animation-curve-default;
}

.dropup .dropdown-menu{
  transform-origin: 0 100%;
}

.navbar-nav > li.open > .dropdown-menu,
.dropdown.open .dropdown-menu,
.dropup.open .dropdown-menu,
.dropdown-menu.bootstrap-datetimepicker-widget.open{
    @include opacity(1);
    transform: scale(1);

}

.dropdown-menu.dropdown-menu-right{
  right: 0;
  left: auto;
  transform-origin: 100% 0;

  .dropup &{
    transform-origin: 100% 100%;
  }
}

.dropdown .dropdown-menu .dropdown-menu{
  transform: scale(0);
  transform-origin: 100% 0;
  right: 102%;
  top: 0;
  left: auto;

  &.dropdown-menu-right{
    transform-origin: 0 0;
    left: 102%;
    right: auto;
  }
}

.dropdown .dropdown-menu .open + .dropdown-menu{
  @include opacity(1);
  transform: scale(1);
}

.md-list-item.dropdown{
  .md-list-item-container{
    a[data-toggle="dropdown"]{
      padding: 10px 15px;
    }

    .md-ripple{
     padding: 0;
   }
  }
}

.dropdown-toggle .md-ripple:after,
.dropdown .dropdown-menu .dropdown-toggle:after{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .35em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  will-change: transform;
  transition: transform .15s linear;

  .open &{
    @include rotate-180();
  }
}

.dropdown .dropdown-menu .dropdown-toggle.open:after{
  @include rotate-180();
}

.dropdown-toggle + .dropdown-menu{
  top: 91%;
}
.dropup .dropdown-toggle + .dropdown-menu{
  top: auto!important;
  bottom: 100%;
}

.sidebar .dropdown-menu li a:hover,
.sidebar .dropdown-menu li a:focus,
.sidebar .dropdown-menu li a:active {
  background-color: rgba(200,200,200,.2) !important;
}
