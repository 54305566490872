.md-toolbar {
  min-height: 56px;
  padding: 10px 15px;
  border-radius: 3px;

  &.md-transparent{
    background-color: transparent !important;
    box-shadow: none;

    .md-title,
    .md-list .md-list-item-content,
    &,
    &:hover,
    &:focus{
      color: $black-color !important;
    }
  }

  &,
  .md-toolbar-row{
    will-change: unset;
  }

  &.md-fixed-top{
    border-radius: 0;
  }

  &.md-toolbar-absolute{
    position: absolute;
    width: 100%;
    padding-top: 10px;
    z-index: 1029;

    .md-title{
      color: $white-color !important;
    }
  }

  .navbar-nav {
    > li > .dropdown-menu{
        margin-top: -20px;
    }

    > li.open > .dropdown-menu{
        margin-top: 0;
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        color: inherit;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: inherit;
        background-color: transparent;
        opacity: 0.9;
      }
    }
    .profile-photo{
        padding: 0 5px 0;
        .profile-photo-small{
            height: 40px;
            width: 40px;
        }
    }
  }


  // default color for dropdown
  @include dropdown-colors($brand-primary);
  @include toolbar-colors($gray-light);
  .md-toolbar-toggle .icon-bar{
    color: inherit !important;
  }

  &.md-primary{
    @include dropdown-colors($brand-primary);
    @include toolbar-colors($brand-primary);
  }
  &.md-danger{
    @include dropdown-colors($brand-danger);
    @include toolbar-colors($brand-danger);
  }
  &.md-warning{
    @include dropdown-colors($brand-warning);
    @include toolbar-colors($brand-warning);
  }
  &.md-info{
    @include dropdown-colors($brand-info);
    @include toolbar-colors($brand-info);
  }
  &.md-success{
    @include dropdown-colors($brand-success);
    @include toolbar-colors($brand-success);
  }
  &.md-rose{
    @include dropdown-colors($brand-rose);
    @include toolbar-colors($brand-rose);
  }

  &.md-white{
    background-color: $white-color !important;
    @include shadow-navbar-color($white-color);

    .md-title{
      color: inherit !important;
    }
  }

  &.navbar-transparent{
      background-color: transparent;
      box-shadow: none;
      border-bottom: 0;

      .logo-container .brand{
          color: $white-color;
      }
  }

  .md-toolbar-row {
    min-height: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .dropdown .md-list-item-content{
    padding: 0;
  }

  .md-list-item{
    .md-button{
      &.md-just-icon{
        width: auto;
        min-width: auto;
        min-height: 48px;

        &,
        &:hover,
        &:focus,
        &:active,
        &.active,
        &:active:focus,
        &:active:hover,
        &.active:focus,
        &.active:hover,
        .open > &.dropdown-toggle,
        .open > &.dropdown-toggle:focus,
        .open > &.dropdown-toggle:hover{
          color: $black-color !important;
        }

        i{
          width: 20px;
          min-width: 20px;

          &,
          &:hover,
          &:focus,
          &:active,
          &.active,
          &:active:focus,
          &:active:hover,
          &.active:focus,
          &.active:hover,
          .open > &.dropdown-toggle,
          .open > &.dropdown-toggle:focus,
          .open > &.dropdown-toggle:hover{
            color: $black-color !important;
          }
        }
      }
    }
  }

  .md-button{
    margin: 0;
  }

  .md-toolbar-toggle .md-button-content{
    display: block;
    align-items: unset;
  }

  .notification{
    position: absolute;
    top: -6px;
    border: 1px solid #FFF;
    right: -8px;
    font-size: 9px;
    background: #f44336;
    color: #FFFFFF;
    z-index: 1;
    min-width: 20px;
    padding: 0px 5px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: block;
  }

  &.md-theme-default.md-transparent,
  &.md-theme-default,
  &.md-theme-default .md-title,
  &.md-theme-default.md-transparent .md-title {
    color: $gray;
  }

  .md-title {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-left: 0;
    margin-right: 15px;
    padding: 10px 0;
    font-weight: 300;
    letter-spacing: normal;
  }

  .md-toolbar-toggle {
    width: 40px;

    .icon-bar {
      position: relative;
      display: block;
      width: 22px;
      height: 2px;
      background-color: inherit;
      border: 1px solid;
      border-radius: 1px;

      & + .icon-bar{
        margin-top: 4px;
      }
    }
  }

  .md-list{
    flex-flow: row;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    color: inherit !important;
    background-color: transparent !important;

    &:hover .md-list-item > a,
    &:focus .md-list-item > a,
    & > a:hover {
      background-color: transparent !important;
    }

    .md-list-item > a:not(:last-child){
      margin-right: 5px;
    }
  }

  .md-field{
    padding: 0;
    margin: 0;

    &:after,
    &:before{
      bottom: 0;
    }

    .md-input{
      margin-top: 6px;
    }

    label{
      top: 15px;
    }

    &.md-focused label,
    &.md-has-value label{
      top: -4px;
    }
  }

  .md-collapse{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.md-menu-content{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: $border-radius-base !important;
  overflow: hidden;

  .md-list .md-list-item a{
    font-size: 10px;
  }
}






.md-list-item a{
  color: inherit !important;
  padding-top: 15px;
  padding-bottom: 15px;

  font-weight: $font-weight-default;
  font-size: $mdb-btn-font-size-base;
  text-transform: uppercase;
  overflow: visible;

  border-radius: $border-radius-base;
  position: relative;
  display: block;
  padding: 0;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    background-color: transparent;
  }


  .material-icons,
  .fa{
        font-size: 20px;
  }

  &.btn:not(.btn-just-icon){
      .fa{
        position: relative;
        top: 2px;
        margin-top: -4px;
        margin-right: 4px;
      }
  }
}

.md-list-item{
  margin-left: 5px;
  list-style: none;
}
