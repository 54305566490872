.md-tabs-navigation{
  @include shadow-big();
  margin: -35px 0 0;
  border-radius: $border-radius-base;
  padding: $padding-base $padding-base $padding-base 75px;
  background-color: $gray-color;


  .md-icon-label .md-button-content{
    flex-direction: row;
  }

  & + .md-tabs-content{
    .md-checkbox.md-checked .md-checkbox-container:after{
      top: 0px;
      left: 5px;
    }
  }

  .md-button,
  .md-button:hover,
  .md-button:focus,
  .md-button:active {
    background-color: transparent !important;
    box-shadow: none;
  }

  .md-active,
  .md-active:hover,
  .md-active:focus{
    background-color: rgba(255, 255, 255, 0.2) !important;
    box-shadow: none;
  }

  .md-icon-label{
    height: auto;
    border-radius: 3px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;

    &:not(:last-child){
      margin-right: 5px;
    }

    .md-tab-icon + .md-tab-label{
      margin-top: 1px;
      margin-left: 5px;
    }

    .md-ripple{
      padding: 10px 15px !important;
    }
  }
}

@media all and (max-width: 540px) {
  .md-tabs-navigation {
    flex-direction: column;
  }
}

.md-tabs-indicator{
  display: none;
}

.md-nav-tabs-title{
  position: absolute;
  z-index: 9999;
  top: 6px;
  color: white;
  font-size: initial;
  margin-left: 15px;
}

.md-tab{
  padding: 15px 0 0 0;
}
