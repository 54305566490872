.badge {
  display: inline-block;
  border-radius: $border-radius-label;
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: $white-color;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  @include badge-color();
}


.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: darken($gray-light, 5%);
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: darken($brand-primary, 5%);
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: darken($brand-info, 5%);
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: darken($brand-success, 5%);
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: darken($brand-warning, 5%);
  color: $white-color;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: darken($brand-danger, 5%);
}
.badge-rose[href]:hover,
.badge-rose[href]:focus {
  background-color: darken($brand-rose, 5%);
}
