.md-collapse {
  position: relative;

  &.is-active .md-collapse-label .md-icon{
    @include rotate-180();
  }

  .md-collapse-title{
    margin: 0;
    display: block;
  }

  @include collapse-color($gray-light);

  &.md-warning{
    @include collapse-color($brand-warning);
  }
  &.md-rose{
    @include collapse-color($brand-rose);
  }
  &.md-primary{
    @include collapse-color($brand-primary);
  }
  &.md-danger{
    @include collapse-color($brand-danger);
  }
  &.md-success{
    @include collapse-color($brand-success);
  }
  &.md-info{
    @include collapse-color($brand-info);
  }
}

.md-collapse-label {
  padding: 25px 10px 20px 0;
  cursor: pointer;
  position: relative;
  // border-bottom: 1px solid #ddd;

  &:after{
    content: "";
    position: absolute;
    bottom: 14px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #ddd;
  }

  .md-collapse-title{
    color: $black-color !important;
    font-size: .9375rem;
  }

  .md-collapse-title .md-icon{
    color: $black-color !important;
    position: absolute;
    right: 7px;
    top: 24px;
  }
}

.md-collapse-content {
  padding: 0px 20px;

  p {
    margin: 0;
    padding-bottom: 20px;
  }
}
