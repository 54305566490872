@media (min-width: 992px){
    .navbar-form {
        margin-top: 21px;
        margin-bottom: 21px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .md-toolbar-toggle{
      display: none;
    }


    .navbar-nav.navbar-right > li > .dropdown-menu:before{
        left: auto;
        right: 12px;
    }

    .navbar-nav.navbar-right > li > .dropdown-menu:after{
        left: auto;
        right: 12px;
    }

    .footer:not(.footer-big){
        nav > ul{
           li:first-child{
             margin-left: 0;
           }
        }
    }

    body > .navbar-collapse.collapse{
        display: none !important;
    }

    .card{
        form{
            [class*="col-"]{
                padding: 6px;
            }
            [class*="col-"]:first-child{
                padding-left: 15px;
            }
            [class*="col-"]:last-child{
                padding-right: 15px;
            }
        }
    }

    .sidebar{
        .navbar-form{
            display: none !important;
        }
        .nav-mobile-menu{
            display: none;
        }
    }
}

/*          Changes for small display      */

@media (max-width: 991px){
  .md-toolbar .md-collapse{
    display: none !important;
  }

  .main-panel > .content{
    padding-left: 0;
    padding-right: 0;
  }

  .off-canvas-sidebar {
    &.md-collapse {
      width: 260px;
      display: block !important;
      height: 100% !important;

      .md-list {
        flex-direction: column;
        margin-top: 15px;
      }
    }
  }

  .navbar-minimize {
    display: none !important;
  }

  .wrapper-full-page,
  .md-toolbar-section-start,
  .md-toolbar-section-end .md-toolbar-toggle  {
    @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .full-page{
    .md-toolbar .md-toolbar-toggle .icon-bar{
      color: $white-color !important;
    }

    .md-list-item:not(:last-child) {
      margin-bottom: 5px;
    }

    &.nav-open {
      .wrapper-full-page {
        transform: translate3d(-260px,0,0);
      }

      .md-toolbar-section-start,
      .md-toolbar-section-end .md-toolbar-toggle {
        transform: translate3d(-260px,0,0);
      }

      &.full-page .off-canvas-sidebar {
        transform: translate3d(0,0,0);
      }
    }
  }

    .sidebar{
        display: none;
        box-shadow: none;

        .sidebar-wrapper{
            padding-bottom: 60px;
        }

        .nav-mobile-menu{
            margin-top: 0;

            .md-field{
              width: auto;
              margin: 15px 30px 0;
            }

            .notification{
                float: left;
                line-height: 30px;
                margin-right: 8px;
            }

            .dropdown-menu li a:hover,
            .dropdown-menu li a:focus,
            .dropdown-menu li a:active {
              box-shadow: none;
            }
        }

        .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            display: none;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .dropdown.open .dropdown-menu{
          display: block;
        }

        .dropdown{
          li:hover a{
            background-color: $brand-primary;
            @include shadow-8dp();
            color: $white-color !important;
          }
        }
    }

    html,
    body{
        overflow-x: hidden;
    }

    .menu-on-left{
        .md-toolbar .md-toolbar-row {
          flex-direction: row-reverse;

          .md-toolbar-section-end {
            justify-content: flex-start;
          }
          .md-toolbar-section-start {
            justify-content: flex-end;
          }
        }

        .full-page.nav-open .md-toolbar-section-start,
        .full-page.nav-open .md-toolbar-section-end .md-toolbar-toggle {
          @include transform-translate-x(260px);
        }

        .nav-open{
            .main-panel,
            .wrapper-full-page,
            .navbar-fixed > div{
                @include transform-translate-x(260px);
            }
        }

        .sidebar,
        .off-canvas-sidebar{
            left: 0;
            right: auto;
            @include transform-translate-x(-260px);
        }

        #bodyClick{
            left: 260px;
            right: auto;
        }
    }

    .main-panel{
        width: 100%;
    }
    .navbar-transparent{
        padding-top: 15px;
        background-color: rgba(0, 0, 0, 0.45);
    }
    body,
    html{
        position: relative;
        overflow-x: hidden;
    }
    .navbar .container{
         left: 0;
          width: 100%;
         @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
         position: relative;
    }

    .navbar-nav > li{
        float: none;
        position: relative;
        display: block;
    }

    .sidebar,
    .off-canvas-sidebar{
        position: fixed;
        display: block;
        top: 0;
        height: 100vh;
        width: 260px;
        right: 0;
        left: auto;
        z-index: 1032;
        visibility: visible;
        background-color: #9A9A9A;
        overflow-y: visible;
        border-top: none;
        padding-right: 0px;
        padding-left: 0;

        @include transform-translate-x(260px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        > ul {
            position: relative;
            z-index: 4;
            overflow-y:scroll;
            height: calc(100vh - 61px);
            width: 100%;
        }
        &::before{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            display: block;
            content: "";
            z-index: 1;
        }

        .logo{
            position: relative;
            z-index: 4;
        }

        .navbar-form{
            margin: 10px 15px;
            float: none !important;
            padding-top: 1px;
            padding-bottom: 1px;
        }

        .table-responsive {
            width: 100%;
            margin-bottom: 15px;
            overflow-x: scroll;
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            -webkit-overflow-scrolling: touch;
        }
    }

    .form-group{
        .form-control{
            font-size: 16px;
            height: 37px
        }
    }

    .navbar-form{
        .btn{
            position: absolute;
            top: 27px;
            right: 15px;
        }
    }

    .main-panel {
      @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

    .nav-open .navbar-collapse{
        @include transform-translate-x(0px);
    }
    .nav-open .navbar .container{
        left: -250px;
    }
    .nav-open .main-panel{
        left: 0;
        @include transform-translate-x(-260px);
    }

    .nav-open .sidebar{
        @include shadow-big();
    }

    .nav-open{
        .sidebar{
            @include transform-translate-x(0);
        }
    }

    .close-layer{
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: auto;

        content: "";
        z-index: 9999;
        overflow-x: hidden;

        @include transition($slow-transition-time, $transition-ease-in);

        &.visible{
            opacity: 1;
        }
    }

    .navbar-toggle .icon-bar {
          display: block;
          position: relative;
          background: #fff;
          width: 24px;
          height: 2px;
          border-radius: 1px;
          margin: 0 auto;
    }

    .navbar-header .navbar-toggle {
        margin: 10px 15px 10px 0;
        width: 40px;
        height: 40px;
    }
    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent;
    }

    @include topbar-x-rotation();
    @include topbar-back-rotation();
    @include bottombar-x-rotation();
    @include bottombar-back-rotation();

    .md-toolbar-toggle{
        .icon-bar:nth-child(2){
          top: 0px;
          @include bar-animation($topbar-back);
        }
        .icon-bar:nth-child(3){
          opacity: 1;
        }
        .icon-bar:nth-child(4){
          bottom: 0px;
          @include bar-animation($bottombar-back);
        }

        &.toggled{
            .icon-bar:nth-child(1){
              top: 6px;
              @include bar-animation($topbar-x);
            }
            .icon-bar:nth-child(2){
              opacity: 0;
            }
            .icon-bar:nth-child(3){
              bottom: 6px;
              @include bar-animation($bottombar-x);
            }
        }
    }

    @-webkit-keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    @-moz-keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    @keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }

    .dropdown-menu .divider{
        background-color: rgba(229, 229, 229, 0.15);
    }

    .navbar-nav {
        margin: 1px 0;

        .open .dropdown-menu > li {
            & > a{
                padding: 15px 15px 5px 50px;
            }

            &:first-child > a{
                padding: 5px 15px 5px 50px;
            }

            &:last-child > a {
                padding: 15px 15px 25px 50px;
            }
        }
    }

    [class*="navbar-"] .navbar-nav {
        & > li > a,
        > li > a:hover,
        > li > a:focus,
        .active > a,
        .active > a:hover,
        .active > a:focus,
        .open .dropdown-menu > li > a,
        .open .dropdown-menu > li > a:hover,
        .open .dropdown-menu > li > a:focus,
        .navbar-nav .open .dropdown-menu > li > a:active {
            color: white;
        }

        & > li > a,
        > li > a:hover,
        > li > a:focus,
        .open .dropdown-menu > li > a,
        .open .dropdown-menu > li > a:hover,
        .open .dropdown-menu > li > a:focus{
            opacity: .7;
            background: transparent;
        }

        &.navbar-nav .open .dropdown-menu > li > a:active {
            opacity: 1;
        }

        & .dropdown > a{
            &:hover .caret {
                border-bottom-color: #777;
                border-top-color: #777;
            }
            &:active .caret {
                border-bottom-color: white;
                border-top-color: white;
            }
        }

    }

    .dropdown-menu {
        display: none;
    }
    .navbar-fixed-top {
        -webkit-backface-visibility: hidden;
    }
    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
    }

    .social-line .btn{
        margin: $margin-bottom;
    }
    .subscribe-line .form-control{
        margin: $margin-bottom;
    }
    .social-line.pull-right{
        float: none;
    }
    .footer:not(.footer-big) nav > ul li{
        float: none;
    }
    .social-area.pull-right{
        float: none !important;
    }
    .form-control + .form-control-feedback{
        margin-top: -8px;
    }
    .navbar-toggle:hover,.navbar-toggle:focus {
        background-color: transparent !important;
    }
    .btn.dropdown-toggle{
        margin-bottom: 0;
    }
    .media-post .author{
        width: 20%;
        float: none !important;
        display: block;
        margin: 0 auto 10px;
    }
    .media-post .media-body{
        width: 100%;
    }

    .navbar-collapse.collapse{
        height: 100% !important;
    }
    .navbar-collapse.collapse.in {
        display: block;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
    .navbar-collapse{
        .nav p{
            font-size: $font-size-base;
            margin: 0;
        }

        [class^="pe-7s-"]{
            float: left;
            font-size: 20px;
            margin-right: 10px;
        }
    }
}

@media (max-width: 768px){
  .footer .container{
    justify-content: unset;
    display: block;

    nav{
      display: block;
    }

    .copyright{
      float: right;
    }
  }

  .md-tabs-content {
    height: 250px !important;
    overflow: auto;
  }

  .login-page.page-header > .container {
    padding-top: 70px;
    padding-bottom: 160px;
  }

  .hidden-sm{
    display: none !important;
  }
}
@media (min-width: 768px){
  .hidden-lg,
  .hidden-md{
    display: none !important;
  }
}

@media screen and (min-width: 768px){
  .block-md{
    display: block !important;
  }
}



@media (max-width: 480px), (max-width: 767px){
    .form-group{
        .form-control{
            width: 83%;
        }
    }

    .navbar-form{
        .form-group{
            margin-bottom: 0;
        }
    }
}

@media (min-width: 992px){
    .table-responsive{
        overflow: visible;
    }

}
@media (max-width: 1100px){
  .paginated-table table > tbody > tr > td {
    max-width: unset;
  }
}

@media screen and (max-width: 576px) {
  .tim-typo{
    padding-left: 11% !important;

    .tim-note{
      bottom: -25px !important;
    }
  }

  .modal-mask .modal-container {
    margin: 0 15px;
  }

  .md-card-tabs.md-card-plain .md-list{
    flex-direction: column;
  }

  .places-buttons .md-button{
    width: 100%;
  }
}
