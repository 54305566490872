// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.alert {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 20px 15px;
    line-height: 20px;
    margin-bottom: 20px;
    z-index: 99999 !important;

    .notifications &{
      margin-bottom: 0;
    }

    b{
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-size: $font-size-small;
    }

    @include alert-color($gray-color);

    &.alert-info{
      @include alert-color(lighten($brand-info,5%));
      @include alert-icon-color($brand-info);
    }

    &.alert-danger{
      @include alert-color(lighten($brand-danger,5%));
      @include alert-icon-color($brand-danger);
    }

    &.alert-primary{
      @include alert-color(lighten($brand-primary,5%));
      @include alert-icon-color($brand-primary);
    }

    &.alert-warning{
      @include alert-color(lighten($brand-warning,5%));
      @include alert-icon-color($brand-warning);
    }

    &.alert-success{
      @include alert-color(lighten($brand-success,5%));
      @include alert-icon-color($brand-success);
    }

    &.alert-rose{
      @include alert-color(lighten($brand-rose,5%));
      @include alert-icon-color($brand-rose);
    }


    &-info, &-danger, &-warning, &-success {
        color: $mdb-text-color-light;
    }

    &-default {
        a, .alert-link {
            color: $mdb-text-color-primary;
        }
    }

    .close{
      float: right;
      font-size: inherit;
      font-weight: 700;
      line-height: 1;
      color: $white-color;
      opacity: .9;
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }

    i[data-notify="icon"] {
      display: block;
      left: 15px;
      position: absolute;
      margin-top: -39px;
      font-size: 20px;
      background-color: #fff;
      padding: 9px;
      border-radius: 50%;
      max-width: 38px;
      box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
    }

    span{
        display: block;
        max-width: 89%;
    }

    .alert-icon{
        display: block;
        float: left;
        margin-right: $margin-base;

        i{
            margin-top: -7px;
            top: 5px;
            position: relative;
        }
    }
}

.alert.alert-with-icon {
  margin-top: 43px;
    padding-left: 65px;
}
