.md-card{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 30px 0;
    overflow: unset;
    font-size: .875rem;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
    border-radius: $border-radius-base + 3;
    color: $mdb-card-body-text;
    background: $mdb-card-body-background;

    .card-height-indicator {
        margin-top: 100%;
    }

    [data-header-animation="true"]{
      transition: all .3s cubic-bezier(.34,1.61,.7,1);
    }

    &:hover [data-header-animation="true"]{
      transform: translate3d(0,-50px,0);

    }

    &.row-space{
        .header{
            padding: 15px 20px 0;
        }
    }

    .md-card-header:first-child{
      border-radius: $border-radius-base + 3;
    }

    .title{
        margin-top: 0;
        margin-bottom: 3px;

        a,
        a:hover,
        a:focus{
          color: $black-color !important;
        }

    }

    .category:not([class*="text-"]){
        color: $gray-color;
    }
    .md-card-content{
        padding: 15px 20px;
        line-height: 1.75em;
        position: relative;

        .category{
            margin: 0;
        }

        .md-form-label{
          color: #aaa;
          font-weight: 400;
          margin-bottom: 0;
          font-size: inherit;
          line-height: 1.5;
          padding: 20px 5px 0 0;
          text-align: right;
        }
    }

    .md-card-action-buttons {
        position: absolute;
        z-index: -1;
        top: -50px;
        width: calc(100% - 30px);
        left: 15px;
        text-align: center;

        .card-header{
          padding: 0;
          min-height: 160px;
        }

        .btn {
            padding-left: 12px;
            padding-right: 12px;
        }
        .fix-broken-card {
            position: absolute;
            top: -45px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.md-card-chart{
        .md-card-header:not(.md-card-header-icon):not(.md-card-header-text){
          padding: 0 !important;
          min-height: 160px;
        }

        + .content{
            h4{
                margin-top: 0;
            }
        }
    }

    .md-card-header{
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: #fff;


        &.md-card-header-icon,
        &.md-card-header-text{
          .category{
              margin: 0;
              color: rgba($white-color, .8);
          }

          .title{
              color: $white-color;
          }
        }

        &.md-card-header-image {
            position: relative;
            padding: 0;
            z-index: 2;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: -30px;
            border-radius: $border-radius-large;

            img {
                width: 100%;
                border-radius: $border-radius-large;
                pointer-events: none;
                @include shadow-big-image();
            }
            .card-title {
                position: absolute;
                bottom: 15px;
                left: 15px;
                color: $white-color;
                font-size: $font-size-h4;
                text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
            }
        }

        .card-icon,
        .card-text{
          border-radius: $border-radius-base;
          background-color: $gray-color;
          padding: 15px;
          margin-top: -20px;
          margin-right: 15px;
          float: left;

          i{
            color: $white-color;
          }
        }

        &.md-card-header-icon,
        &.md-card-header-text{
          .title{
            color: $black-color;
            margin-top: 15px;
          }

          i {
              width: 33px;
              height: 33px;
              text-align: center;
              line-height: 33px;
          }
        }





        h4{
          font-weight: 300;
        }

        a{
          color: $white-color !important;
        }

        .ct-label{
            color: rgba($white-color, .7);
        }
        .ct-grid{
            stroke: rgba(255, 255, 255, 0.2);
        }
        .ct-series-a .ct-point,
        .ct-series-a .ct-line,
        .ct-series-a .ct-bar,
        .ct-series-a .ct-slice-donut{
            stroke: rgba(255,255,255,.8);
        }
        .ct-series-a .ct-slice-pie,
        .ct-series-a .ct-area{
            fill: rgba(255,255,255,.4);
        }

    }

    [class*=md-card-header-]{
      margin: 0 $margin-base;
      border-radius: $border-radius-base;
      padding: 0;
      background-color: transparent;

      .card-text{
        float: none;
        display: inline-block;
        margin-right: 0;

        .title{
          color: $white-color;
          margin-top: 0;
        }

        .category{
          font-size: .875rem;
        }
      }

      &:not(.md-card-header-icon):not(.md-card-header-text):not(.md-card-header-image){
        margin-top: -20px;
        padding: 15px;
        z-index: 2;
      }
    }

    .chart-title{
        position: absolute;;
        top: 25px;
        width: 100%;
        text-align: center;

        h3{
            margin: 0;
            color: $white-color;
        }

        h6{
            margin: 0;
            color: rgba(255,255,255, .4);
        }

    }

    .md-card-actions{
        margin: 0 15px 10px;
        font-size: 14px;
        padding: 10px 0 0 0;
        border-top: 1px solid #eeeeee;

        .content{
            display: block;
        }

        .md-button{
          margin: 5px 1px;
        }

        // div{
        //     display: inline-block;
        // }
        .author{
            color: $gray-color;
        }
        .stats{
            line-height: 22px;
            color: $gray-color;
            font-size: $font-size-small;

            .md-icon.md-theme-default.md-icon-font{
                position: relative;
                top: -1px;
                font-size: $font-paragraph + 2 !important;
                color: $gray-light;
            }

            .category{
              padding-top: 7px;
              padding-bottom: 7px;
              margin: 0;
            }
        }

        h6{
            color: $gray-color;
        }

    }

    img{
        width: 100%;
        height: auto;
    }

    .category{
        .md-icon{
            position: relative;
            top: 6px;
            line-height: 0;
        }
    }

    .category-social{
        .fa{
            font-size: 24px;
            position: relative;
            margin-top: -4px;
            top: 2px;
            margin-right: 5px;
        }
    }

    .author{
        .avatar{
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 5px;
        }

        a{
            color: $black-color;
            text-decoration: none;

            .ripple-container{
                display: none;
            }
        }
    }

    .table{
        margin-bottom: 0;

        tr:first-child td{
            border-top: none;
        }
    }

    .md-card-header-primary .card-icon,
    .md-card-header-primary .card-text,
    .md-card-header-primary:not(.md-card-header-icon):not(.md-card-header-text),
    [data-background-color="purple"],
    .md-tabs.md-primary .md-tabs-navigation{
        background: linear-gradient(60deg, $purple-400, $purple-600);
    }

    .md-card-header-blue .card-icon,
    .md-card-header-blue .card-text,
    .md-card-header-blue:not(.md-card-header-icon):not(.md-card-header-text),
    [data-background-color="blue"],
    .md-tabs.md-info .md-tabs-navigation{
        background: linear-gradient(60deg, $cyan-400, $cyan-600);
    }

    .md-card-header-green .card-icon,
    .md-card-header-green .card-text,
    .md-card-header-green:not(.md-card-header-icon):not(.md-card-header-text),
    [data-background-color="green"],
    .md-tabs.md-success .md-tabs-navigation{
        background: linear-gradient(60deg, $green-400, $green-600);
    }

    .md-card-header-warning .card-icon,
    .md-card-header-warning .card-text,
    .md-card-header-warning:not(.md-card-header-icon):not(.md-card-header-text),
    [data-background-color="orange"],
    .md-tabs.md-warning .md-tabs-navigation{
        background: linear-gradient(60deg, $orange-400, $orange-600);
    }

    .md-card-header-danger .card-icon,
    .md-card-header-danger .card-text,
    .md-card-header-danger:not(.md-card-header-icon):not(.md-card-header-text),
    [data-background-color="red"],
    .md-tabs.md-danger .md-tabs-navigation{
        background: linear-gradient(60deg, $red-400, $red-600);
    }

    .md-card-header-rose .card-icon,
    .md-card-header-rose .card-text,
    .md-card-header-rose:not(.md-card-header-icon):not(.md-card-header-text),
    [data-background-color="rose"],
    .md-tabs.md-rose .md-tabs-navigation{
        background: linear-gradient(60deg, $pink-400, $pink-600);
    }




    [data-background-color="red"],
    .md-tabs.md-danger .md-tabs-navigation,
    .md-card-header-danger .card-icon,
    .md-card-header-danger:not(.md-card-header-icon):not(.md-card-header-text),
    .md-card-header-danger .card-text{
        @include shadow-big-color($brand-danger);
    }

    [data-background-color="purple"],
    .md-tabs.md-primary .md-tabs-navigation,
    .md-card-header-danger .card-icon,
    .md-card-header-danger:not(.md-card-header-icon):not(.md-card-header-text),
    .md-card-header-danger .card-text{
        @include shadow-big-color($brand-primary);
    }

    [data-background-color="rose"],
    .md-tabs.md-rose .md-tabs-navigation,
    .md-card-header-rose .card-icon,
    .md-card-header-rose:not(.md-card-header-icon):not(.md-card-header-text),
    .md-card-header-rose .card-text{
        @include shadow-big-color($brand-rose);
    }

    [data-background-color="orange"],
    .md-tabs.md-warning .md-tabs-navigation,
    .md-card-header-warning .card-icon,
    .md-card-header-warning:not(.md-card-header-icon):not(.md-card-header-text),
    .md-card-header-warning .card-text{
        @include shadow-big-color($brand-warning);
    }

    [data-background-color="blue"],
    .md-tabs.md-info .md-tabs-navigation,
    .md-card-header-blue .card-icon,
    .md-card-header-blue:not(.md-card-header-icon):not(.md-card-header-text),
    .md-card-header-blue .card-text{
        @include shadow-big-color($brand-info);
    }

    [data-background-color="green"],
    .md-tabs.md-success .md-tabs-navigation,
    .md-card-header-green .card-icon,
    .md-card-header-green:not(.md-card-header-icon):not(.md-card-header-text),
    .md-card-header-green .card-text{
        @include shadow-big-color($brand-success);
    }

    [data-background-color]{
        color: $white-color;

        a{
            color: $white-color;
        }
    }
}

.md-card-stats{
    .title{
        margin: 0;

        small{
          color: $gray-color;
          font-size: 65%;
          line-height: 1;
          font-weight: 400;
        }
    }
    .md-card-header{
        text-align: center;

        .category:not([class*=text-]){
          color: $gray-light;
          font-size: 14px;
        }

        .card-icon + .category,
        .card-icon + .title{
          padding-top: 10px;
        }

        & + .md-card-actions{
          margin-top: 20px;
        }

        &.md-card-header-icon,
        &.md-card-header-text{
          text-align: right;

          .title{
            margin: 0;
          }

          i{
            font-size: 36px !important;
            line-height: 56px;
            width: 56px;
            height: 56px;
            text-align: center;
          }

        }
    }
    .md-card-content{
        text-align: right;
        padding-top: 10px;
    }

}

.card-nav-tabs{
    .header-raised{
        margin-top: -$margin-base * 2;
    }
    .nav-tabs{
        background: transparent;
        padding: 0;
    }
    .nav-tabs-title{
        float: left;
        padding: 10px 10px 10px 0;
        line-height: 24px;
    }
}

.md-card-plain{
    background: transparent !important;
    box-shadow: none !important;

    // .md-card-header{
    //     margin-left: 0;
    //     margin-right: 0;
    // }
    .content{
        padding-left: 5px;
        padding-right: 5px;
    }

    .md-card-header:not(.md-card-avatar) .category,
    & .md-card-header:not(.md-card-avatar) .description{
      color: $gray-color;
    }

    .card-image{
        margin: 0;
        border-radius: $border-radius-base;

        img{
            border-radius: $border-radius-base;
        }
    }

    .md-content.md-theme-default{
      background-color: transparent !important;
    }
}

.iframe-container{
    margin: 0 -20px 0;

    iframe{
        width: 100%;
        height: 500px;
        border: 0;
        @include shadow-big();
    }
}

.md-card-profile,
.card-testimonial{
    margin-top: 30px;
    text-align: center;

    .btn-just-icon.btn-raised{
        margin-left: 6px;
        margin-right: 6px;
    }

    .md-card-avatar{
        max-width: 130px;
        max-height: 130px;
        margin: -50px auto 0;
        border-radius: 50%;
        overflow: hidden;

        @include shadow-big();

        & + .md-card-content{
            margin-top: 15px;
        }
    }

    &.md-card-plain{
        .card-avatar{
            margin-top: 0;
        }
    }
}
