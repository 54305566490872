.md-select-menu {
  .md-list-item {
    margin: 0 .3125rem;
    border-radius: $border-radius-small;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    .md-list-item-button {
      background-color: transparent;
      color: $gray-dark !important;
      border-radius: 3px;

      &:hover {
        background-color: $brand-primary !important;
        @include shadow-big-color($brand-primary);
        color: #FFF !important;
      }
    }

    .md-list-item-content {
      min-height: 38px;
      font-size: $font-size-base - 1;

      span {
        top: auto;
        left: auto;
        right: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .md-list-item.md-selected .md-list-item-button {
    background-color: $brand-primary;
    @include shadow-big-color($brand-primary);

    .md-list-item-content {
      color: #FFF !important;
    }
  }

  .md-list-item-content>.md-checkbox:first-child,
  .md-list-item-content>.md-radio:first-child {
    margin: 0;
    margin-left: auto;
  }

  .md-checkbox.md-checked
  .md-checkbox-container:after {
    border-color: $white-color !important;
  }

  .md-checkbox .md-checkbox-container {
    border-color: transparent !important;

    .md-ripple{
      overflow: visible;
    }
  }
}

.md-menu.md-select:not(.md-disabled) .md-icon {
  margin-bottom: 5px;
}

.md-menu.md-select {
  overflow: hidden;
}
