%common-card-testimonial {
    margin-top: 30px;
    text-align: center;


    .avatar {
        margin: -50px auto 0;
        border-radius: 50%;
        overflow: hidden;
        padding: 0;

        @include shadow-big();

        & + .md-card-content {
            margin-top: 15px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }

    .md-card-content + .md-card-actions {
        flex-direction: inherit;
    }

    .md-card-actions {
        .btn.btn-just-icon {
            font-size: 20px;
            padding: 12px 12px;
            line-height: 1em;
        }

        .category{
          margin-top: 10px;
          margin-bottom: .5rem;
        }
    }

    &.md-card-plain {
        .avatar {
            margin-top: 0;
        }
    }
}

.md-card-testimonial {

  @extend %common-card-testimonial;

  .avatar {
      max-width: 100px;
      max-height: 100px;
  }

  .md-card-header{
    margin: 0;
    padding: 0;
  }

  .md-card-actions {
      margin-top: 0;
      display: block;
      border: 0;

      .avatar {
          margin-top: 10px;
          margin-bottom: -60px;
      }
  }

  .md-card-content {
    .description {
      margin-top: 0;
    }
  }

  .description {
      font-style: italic;

      & + .title {
          margin-top: 30px;
      }
  }
  .icon {
    margin-top: 30px;
    line-height: 2.95;

    .md-icon {
      font-size: 40px !important;
      color: inherit;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}
