.swal2-container .md-button{
  padding: 12px 30px;
  min-width: auto;
  margin: 0 5px;
}

.swal2-popup{
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
  width: 31.25rem !important;
  min-height: 190px !important;

  .swal2-title{
    line-height: 60px !important;
    margin: 0 !important;
  }

  .swal2-actions{
    margin-top: 26px !important;
  }
}

.swal2-icon{
  width: 80px !important;
  height: 80px !important;
  margin: 20px auto 30px !important;
  border: 4px solid transparent !important;
}

.swal2-icon.swal2-warning{
  border-color: #facea8 !important;
}

.swal2-icon.swal2-info .swal2-icon-text, .swal2-icon.swal2-warning .swal2-icon-text{
  font-size: 60px;
line-height: 80px;
text-align: center;
}

.swal2-content{
  .md-input{
    background: no-repeat bottom,50% calc(100% - 1px);
    background-size: 0 100%,100% 100%;
    border: 0;
    height: 36px;
    transition: background 0s ease-out;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    font-size: 14px;
    background-image: linear-gradient(0deg,#9c27b0 2px,rgba(156,39,176,0) 0),linear-gradient(0deg,#d2d2d2 1px,hsla(0,0%,82%,0) 0);
  }

  .md-input:focus{
    background-size: 100% 100%, 100% 100% !important;
    transition-duration: .3s !important;
  }

  .md-field.md-theme-default:after,
  .md-field:not(.md-disabled):after{
    background-color: transparent !important;
  }
}

.swal2-icon.swal2-error {
  border-color: #f27474 !important;
}
