.fixed-plugin{
    position: fixed;
    right: 0;
    width: 64px;
    background: rgba(0,0,0,.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    top: 85px;

    li > a,
    .badge{
        transition: all .34s;
        -webkit-transition: all .34s;
        -moz-transition: all .34s;
    }

    .fa-cog{
        color: #FFFFFF;
        padding: 10px;
        border-radius: 0 0 6px 6px;
        width: auto;
    }

    .dropdown{
      cursor: pointer;
    }

    .dropdown-menu{
        right: 80px;
        left: auto !important;
        top: -41px !important;
        width: 290px;
        border-radius: 10px;
        padding: 0 10px;
    }

    .dropdown .dropdown-menu .now-ui-icons{
      top: 5px;
    }

    .dropdown-menu:after,
    .dropdown-menu:before{
        right: 10px;
        margin-left: auto;
        left: auto;
    }

    .fa-circle-thin{
        color: #FFFFFF;
    }

    .active .fa-circle-thin{
        color: #00bbff;
    }

    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus{
        color: #777777;
        text-align: center;
    }

    img{
        border-radius: 0;
        width: 100%;
        height: 100px;
        margin: 0 auto;
    }

    .dropdown-menu li > a:hover,
    .dropdown-menu li > a:focus{
        box-shadow: none;
    }

    .badge{
        border: 3px solid #FFFFFF;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 23px;
        margin-right: 5px;
        position: relative;
        width: 23px;
        padding: 8px;
    }

    .badge.active,
    .badge:hover{
        border-color: #00bbff;
    }

    .badge-azure{
        background-color: $brand-info;
    }
    .badge-green{
        background-color: $brand-success;
    }
    .badge-purple{
        background-color: $brand-primary;
    }
    .badge-orange{
        background-color: $brand-warning;
    }
    .badge-red{
        background-color: $brand-danger;
    }
    .badge-black{
        background-color: #000;
    }
    .badge-white{
        background-color: rgba(200,200,200,.2);
    }

    h5{
        font-size: 14px;
        margin: 10px;
    }

    .dropdown-menu li{
        display: block;
        padding: 18px 2px;
        width: 25%;
        float: left;
        font-weight: 400;
        font-size: 0.95em;
    }

    li.adjustments-line,
    li.header-title,
    li.button-container{
        width: 100%;
        height: 50px;
        min-height: inherit;
    }

    li.button-container{
      height: auto;

      .md-button.md-just-icon{
        margin-left: 5px;
        margin-right: 5px;
      }

      div a{
        margin: 0;
      }
    }

    #sharrreTitle{
        text-align: center;
        padding: 10px 0;
        height: 50px;
    }

    li.header-title{
        height: 30px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
    }

    .github-buttons{
      width: 100% !important;
      display: flex !important;
      justify-content: center;
      margin-bottom: 10px;

      .gh-button,
      .social-count{
        color: inherit !important;
        background-color: inherit !important;
        background-position: unset;
      }
    }

    .adjustments-line{
        p{
            float: left;
            display: inline-block;
            margin-bottom: 0;
            font-size: 1em;
            color: #3C4858;
        }

        &.sidebar-mini,
        &.sidebar-img {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        a{
            color: transparent;

            .badge-colors{
                position: relative;
                top: -2px;
            }

            a:hover,
            a:focus{
                color: transparent;
            }
        }

        .togglebutton{
            text-align: center;

            .label-switch{
              position: relative;
              left: -10px;
              font-size: $font-size-base;
              color: $gray-light;

              &.label-right{
                left: 10px;
              }
            }

            .toggle{
                margin-right: 0;
            }
        }

        .dropdown-menu > li.adjustments-line > a{
              padding-right: 0;
              padding-left: 0;
              border-bottom: 1px solid #ddd;
              border-radius: 0;
              margin: 0;
        }
    }



    .dropdown-menu{
        > li{
            & > a.img-holder{
                  font-size: 16px;
                  text-align: center;
                  border-radius: 10px;
                  background-color: #FFF;
                  border: 3px solid #FFF;
                  padding-left: 0;
                  padding-right: 0;
                  opacity: 1;
                  cursor: pointer;
                  display: block;
                  max-height: 100px;
                  overflow: hidden;
                  padding: 0;

                  img{
                     margin-top: auto;
                  }
            }

            a.switch-trigger:hover,
            & > a.switch-trigger:focus{
                background-color: transparent !important;
            }

            &:hover,
            &:focus{
                > a.img-holder{
                    border-color: rgba(0, 187, 255, 0.53);;
                }
            }
        }

        > .active > a.img-holder,
        > .active > a.img-holder{
            border-color: #00bbff;
            background-color: #FFFFFF;
        }

    }

    span.md-button {
      padding: 12px 30px;
      font-size: .75rem;

      &:not(:last-child) {
        margin-right: 5px;
      }

      i {
        font-size: 1.1rem;
        width: auto;
        min-width: auto;
        vertical-align: middle;
      }
    }

    .btn-social{
        width: 50%;
        display: block;
        width: 48%;
        float: left;
        font-weight: 600;
    }

    .btn-social{
        i{
            margin-right: 5px;
        }

        &:first-child{
            margin-right: 2%;
        }
    }

    .dropdown{
        .dropdown-menu{
          transform-origin: 100% 0;
             &:before{
               border-left: 16px solid rgba(0,0,0,.2);
              right: -16px;
             }

             &:after{
               border-left: 16px solid #fff;
               right: -15px;
             }

             &:before,
             &:after{
               content: "";
               border-bottom: 16px solid transparent;
               border-top: 16px solid transparent;
                display: inline-block;
                position: absolute;
                top: 65px;
                width: 16px;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
             }
        }

        &.show .dropdown-menu{
             opacity: 1;
             visibility: visible;
            transform: scale(1);
             transform-origin: 100% 0;
        }
    }

    .bootstrap-switch{
        margin:0;
    }
}

.fixed-plugin .dropdown-menu > li > a.img-holder{
      font-size: 16px;
      text-align: center;
      border-radius: 10px;
      background-color: #FFF;
      border: 3px solid #FFF;
      padding-left: 0;
      padding-right: 0;
      opacity: 1;
      cursor: pointer;
      display: block;
      max-height: 100px;
      overflow: hidden;
      padding: 0;
      min-width: 25%;
}

.fixed-plugin .dropdown .dropdown-menu li.adjustments-line {
    border-bottom: 1px solid #ddd;
}

.fixed-plugin .dropdown .dropdown-menu li {
    padding: 5px 2px!important;
}

.text-center {
  text-align: center;
}
