.md-table-cell-container{
  padding: 0;
  .md-table-cell:last-child &{
    padding-right: 0;
  }
  .md-button{
    margin: 0;
    padding: 5px;
    height: 27px;
    min-width: 27px;
    width: 27px;
    line-height: 27px;

    &:not(:first-child):not(:last-child){
      margin-left: 3px;
      margin-right: 3px;
    }

    .md-button-content i{
      font-size: 1.1rem !important;
    }
  }
}
.md-table-head-container{
  height: 28px;
  padding: 0;
}

.md-table-head{
  padding: 12px 8px;
}

.md-tabs-content table thead{
  display: none;
}

.md-tabs-container tbody .md-table-row:first-child td{
  border: 0;
}

.md-table-cell{
  padding: 12px 8px;
  font-size: $font-size-base;
}

.md-table-head-label{
  font-size: $font-size-h5;
  padding-left: 0;
  padding-right: 10px;
  font-weight: 300;
  color: $gray-dark;
}

.md-table{
  .md-checkbox.md-checked .md-checkbox-container:after{
    top: 0px;
    left: 5px;
  }
}

.md-table .md-table-row:hover .md-table-cell{
  background-color: transparent !important;
}

// .md-table .md-table-row:hover{
//   background: $table-bg-hover !important;
//
.md-table-head:last-child .md-table-head-label{
  padding-right: 0;
}

.md-card-plain .md-card-content > div .md-table .md-table-content,
.md-card-plain .md-card-content > div .md-table {
  background-color: transparent !important;
}

.md-table{
  &[table-header-color="orange"] .md-table-head .md-table-head-label{
    color: $brand-warning !important;
  }
  &[table-header-color="purple"] .md-table-head .md-table-head-label{
    color: $brand-primary !important;
  }
  &[table-header-color="blue"] .md-table-head .md-table-head-label{
    color: $brand-info !important;
  }
  &[table-header-color="red"] .md-table-head .md-table-head-label{
    color: $brand-danger !important;
  }
  &[table-header-color="green"] .md-table-head .md-table-head-label{
    color: $brand-success !important;
  }

  .flag img {
    max-width: 18px;
    margin-top: -2px;
  }

  .md-table-row td{
    border-top-color: #ddd !important;
  }
}

.md-table-global-sales{
  .md-table-row:hover,
  .md-table-row:focus{
    background: transparent !important;
  }

  .md-table-row:first-child .md-table-cell{
    border: 0;
  }
  .md-table-cell:nth-child(3) .md-table-cell-container{
    display: flex;
    justify-content: flex-end;
  }
}

.table-striped{
  tbody > tr:nth-of-type(odd){
    background-color: #f9f9f9;
  }
}

.table-success{
  background-color: #cde9ce;
}
.table-info{
  background-color: #b8ecf3;
}
.table-danger{
  background-color: #fccac7;
}
.table-warning{
  background-color: #fff9c8;
}

.table-shopping{
    table > thead > tr > th{
        font-size: $font-size-h6;
        text-transform: uppercase;
    }
    table > thead > tr > .md-table-head .md-table-head-label{
        font-size: .75rem;

        b{
            display: block;
            margin-bottom: 5px;
        }
    }
    .td-name{
        font-weight: $font-weight-default;
        font-size: 1.5em !important;
        line-height: 1.42857143;

        small{
            color: $gray-light;
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number{
       font-weight: $font-weight-light;
       font-size: $font-size-h4;
   }
    .td-name{
        min-width: 200px;
    }
    .td-number{
        text-align: right;
        min-width: 150px;

        small{
            margin-right: 3px;
        }
    }

    .img-container{
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;

        img{
            width: 100%;
        }
    }
}

.paginated-table{
  .md-table-toolbar{
    justify-content: space-between !important;
    padding: 0 !important;
    padding-top: 10px !important;

    .md-field {
      width: auto;
    }
  }

  &.md-table{
    display: grid;
  }

  .md-table-head.md-sortable .md-table-sortable-icon,
  .md-table-head.md-table-cell-selection+.md-sortable .md-table-sortable-icon{
    left: auto;
    right: 0;
  }

  .md-table-cell:last-child .md-table-cell-container,
  .md-table-head:last-child{
    text-align: right !important;
    display: flex;
    justify-content: flex-end;
  }

  .md-table-head {
    padding: 0;
  }

  .md-table-head-container.md-ripple{
    padding: 5px;
    height: auto;
    overflow: hidden;
  }

  table>tbody>tr>td{
    padding: 5px!important;
    outline: 0;
    max-width: 150px;
    width: 150px;
    border-right: 0;
    border-bottom: 0;
  }

  .md-table-head-label{
    font-size: 1.063rem;
    padding-right: 20px;
  }

  table>tbody>tr:first-child>td{
    border-top: 0 !important;
  }

  .md-btn-fab, .md-just-icon{
    height: 41px;
    min-width: 41px;
    width: 41px;
    margin: .3125rem -10px;
  }

  .md-table-cell-container .md-button .md-button-content i{
    font-size: 18px !important;
  }

}

.table-hover tbody tr:hover{
  background-color: rgba(0,0,0,.075);
}

.footer-table{
  table > tfoot > tr > th{
    padding: 5px 8px;
    outline: 0;
    max-width: 150px;
    width: 150px;
    border-right: 0;
    border-bottom: 0;
    border-top:1px solid #ddd;
  }

  .md-table-head:last-child{
    text-align: right;
  }

  .md-table-head-label{
    font-size: 14px;
    font-weight: bold;
  }
}

.table-full-width {
  margin-left: -20px;
  margin-right: -20px;

  .md-table-head:first-child .md-table-head-container{
    padding-left: 20px;
  }

  .md-table-cell:first-child {
    padding-left: 28px;
  }
}
