.md-list-item{
  .md-list-item-container{
    .md-ripple{
      padding: 10px 15px;
      border-radius: $border-radius-base;

      > span{
        border-radius: $border-radius-base;
      }
    }
  }
}

.full-page{
  .container{
    width: 100%;
  }


  .md-toolbar{
    .md-list .md-list-item .md-list-item-content{
      font-weight: 500;

      &,
      i{
        color: #fff !important;
      }

      i{
        margin-right: 3px;
        max-width: 24px;
        font-size: 1.25rem !important;
        margin-left: 0;
      }

    }

    .md-list-item{
      margin-right: 5px;
    }

    .md-list-item .md-list-item-container{
      &:hover,
      &:focus{
        background-color: rgba(199,199,199, .2) !important;
      }

      &.active{
        background-color: rgba(199,199,199, .1) !important;
      }
    }

  }
}

.sidebar,
.off-canvas-sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 260px;
    background: $white-color;
    width: 260px;
    @include shadow-big();
    background-size: cover;
    background-position: 50%;


    .navbar-minimize{
      position: absolute;
      right: 13px;
      top: 10px;
      opacity: 1;
      @extend .animation-transition-general;

      .md-transparent{
        &,
        &:hover,
        &:focus,
        &:active{
          background-color: transparent !important;
          box-shadow: none;
        }
      }
    }

    .caret{
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    .md-icon.md-theme-default.md-icon-font,
    .material-icons{
      color: #a9afbb !important;
      opacity: .8
    }

    .md-list{
      background-color: transparent !important;

      .md-list-item a{
        text-transform: capitalize;
      }

      .md-list-item-content{
        justify-content: flex-start;
        min-height: auto;
      }

      & .md-list-item-container:hover{
        background-color: rgba(200, 200, 200, 0.2) !important;
      }
    }

    .md-ripple span .md-ripple-wave{
      background-color: rgba(200, 200, 200, 0.4) !important;
    }

    &[data-background-color="black"]:after{
        background-color: rgba(0,0,0, .77);
    }

    &[data-background-color="white"] {
      &:after {
        background-color: rgba(255,255,255, .93);
      }
    }


    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 75px);
        overflow: auto;
        width: 260px;
        z-index: 4;

        .dropdown .dropdown-backdrop{
            display: none !important;
        }

        .navbar-form{
            border: none;
        }

        .nav{
            padding: 0;

            .caret{
                margin-top: 13px;
                position: absolute;
                right: 5px;
            }
        }

        > .nav,
        .user .user-info{
            [data-toggle="collapse"] ~ div > ul > li > a{
                span{
                    display: inline-block;
                    transition: transform $general-transition-time $transition-linear, opacity $general-transition-time $transition-linear;
                }

                .sidebar-normal{
                    margin: 0;
                    position: relative;
                    transform: translateX(0px);
                    opacity: 1;
                    white-space: nowrap;
                    display: block;

                }

                .sidebar-mini{
                    text-transform: uppercase;
                    width: 30px;
                    margin-right: 15px;
                    text-align: center;
                    letter-spacing: 1px;
                    position: relative;
                    float: left;
                    display: initial;
                }

                i{
                    font-size: 17px;
                    line-height: 20px;
                    width: 26px;
                }
            }
        }

    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }



    .nav{
        margin-top: 15px;

        &.nav-mobile-menu{
          li a {
            padding: 8px 10px;

            &,
            i {
              color: $white-color !important;
            }
          }

          .dropdown-menu li a:hover,
          .dropdown-menu li a:focus,
          .dropdown-menu li a:active {
            box-shadow: none;
          }
        }

        li{

          &.active>[data-toggle="collapse"]{
            background-color: rgba(200, 200, 200, 0.2);
          }

          a{
            display: block;
            text-decoration: none;
            margin: 10px 15px 0;
            border-radius: $border-radius-base;
            color: $black-color !important;
            white-space: nowrap;
            padding: 8px 10px;
            font-size: 13px;
            cursor: pointer;
            width: auto;

            & + div .nav{
              padding-top: 15px;
              margin: 0;
            }
          }

            &:first-child > a{
                margin: 0 15px;
            }

            &:hover > a,
            .dropdown:hover > a{
                background: rgba(200, 200, 200, 0.2);
                color: $black-color;
            }

            .active {
                color: #FFFFFF !important;

                .md-ripple i{
                    opacity: 1;
                }
            }
        }

        p{
          margin: 0;
          line-height: 30px;
          font-size: 14px;
          position: relative;
          display: block;
          height: auto;
          font-weight: $font-weight-light;
          white-space: nowrap;
          transition: transform $general-transition-time $transition-linear, opacity $general-transition-time $transition-linear ;
        }

        i{
            font-size: 24px !important;
            float: left;
            margin-right: 15px !important;
            margin-left: 0;
            line-height: 30px;
            width: 30px;
            height: 30px;
            margin: 0;
            text-align: center;
            color: #a9afbb;
        }
    }

    .logo{
        padding: 15px 0px;
        margin: 0;
        display: block;
        position: relative;
        z-index: 4;

        a.logo-mini,
        a.logo-normal{
            @extend .animation-transition-general;
        }

        a.logo-mini{
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 23px;
            margin-right: 13px;
            position: relative;
            top: -3px;
        }

        a.logo-normal{
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: rgba(180,180,180, .3);

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: inline-block;
            font-size: 18px;
            white-space: nowrap;
            font-weight: $font-weight-default;
            line-height: 30px;
            overflow: hidden;

            &,
            &:hover,
            &:focus{
              color: $white-color !important;
            }

            .logo-img{
              display: flex;
              align-items: center;
              justify-content: center;
              height: 34px;
              width: 34px;
              background: $white-color;
              border-radius: 50%;
              text-align: center;
              overflow: hidden;


                img{
                  width: 80%;
                  vertical-align: unset;
                  position: relative;
                  top: 2px;
                }
            }
        }
    }

    .user{
        padding-bottom: 20px;
        margin:20px auto 0;
        position: relative;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: rgba(180,180,180, .3);
        }

        .photo{
            width: 34px;
            height: 34px;
            overflow: hidden;
            float: left;
            z-index: 5;
            margin-right: 13px;
            border-radius: 50%;
            margin-left: 23px;
            @include shadow-big();
            transition: transform $general-transition-time $transition-linear, opacity $general-transition-time $transition-linear ;

            img{
                width: 100%;
            }
        }

        a{
            color: $black-color;
            padding: .5rem 15px;
            white-space: nowrap;
            cursor: pointer;
        }

        .user-info{
            > a{
               display: block;
               line-height: 18px;
               font-size: 14px;
               transition: none;
               color: inherit !important;

                > span{
                   @extend .animation-transition-general;
                   display: block;
                   position: relative;
                   opacity: 1;
                }
            }

            .nav{
              margin: 0;
              padding-top: 20px;
            }

            .caret{
                position: absolute;
                top: 8px;
                right: 15px;
            }
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:after{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(27, 27, 27, 0.85);
        top: 0;
        left: 0;
        z-index: 2;
    }

    &[data-background-color="black"] {
        @include sidebar-background-color($gray-base, $white-color);

        .nav .md-icon.md-theme-default.md-icon-font,
        .navbar-minimize .md-button i{
          color: $white-color !important;
        }

        .navbar-minimize .md-button i {
          opacity: 1;
        }
    }
    &[data-background-color="white"] {
        .logo .simple-text,
        .logo .simple-text:hover,
        .logo .simple-text:focus{
          color: $black-color !important;
        }

        .navbar-minimize .md-button {
          i {
            color: #a9afbb !important;
          }

          .md-ripple .md-ripple-wave {
            background-color: #a9afbb !important;
          }
        }
    }

    &[data-color="purple"]{
        @include set-background-color-button($brand-primary);
    }
    &[data-color="azure"]{
        @include set-background-color-button($brand-info);
    }
    &[data-color="green"]{
        @include set-background-color-button($brand-success);
    }
    &[data-color="orange"]{
        @include set-background-color-button($brand-warning);
    }
    &[data-color="danger"]{
        @include set-background-color-button($brand-danger);
    }
    &[data-color="rose"]{
        @include set-background-color-button($brand-rose);
    }

    &[data-color="white"]{
        @include set-background-color-button($white-color);
        @include sidebar-active-color($black-color);
    }

    &[data-background-color="red"]{
      @include sidebar-background-color($brand-danger, $white-color);

      &:before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: .45;
        background: #000;
      }

      &:after {
        background-color: $brand-danger;
        opacity: .8;
      }

      .logo:after,
      .user:after {
        background-color: rgba(255, 255, 255, 0.3);
      }

      .md-icon.md-theme-default.md-icon-font {
        color: $white-color !important;
      }

      .nav li:not(.active):hover > a,
      .nav li.active > [data-toggle="collapse"],
      .nav li .dropdown:hover > a {
        background-color: rgba(255,255,255,.1);
      }

      .navbar-minimize .md-button {
        i {
          color: $white-color !important;
          opacity: 1;
        }

        .md-ripple .md-ripple-wave {
          background-color: $white-color !important;
        }
      }

    }
}

.perfect-scrollbar-on{
  .sidebar,
  .main-panel{
      height: 100%;
      max-height: 100%;
  }
}

.main-panel{
    position: relative;
    float: right;
    background-color: #EEEEEE;
    width: $sidebar-width;
    @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > .content{
        padding: 30px 15px;
        min-height: calc(100vh - 123px);
    }

    > .footer{
        border-top: 1px solid #e7e7e7;
    }

    > .navbar{
        margin-bottom: 0;
    }

  .header {
    margin: 0 10px 30px;

    .title{
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .category{
      margin-top: 0;
    }
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper{
    transition-property: top,bottom, width, background-image;
    transition-duration: .2s,.2s, .35s, .30s;
    transition-timing-function: linear,linear,ease,linear;
    -webkit-overflow-scrolling: touch
}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

@media (min-width: 991px) {
    .sidebar-mini{
        .visible-on-sidebar-regular{
            display: none !important;
        }
        .visible-on-sidebar-mini{
            display: inline-block !important;
        }

        .navbar-minimize{
          opacity: 0;
        }

        .sidebar,
        .sidebar .sidebar-wrapper{
            width: 80px;
        }

        .main-panel{
            width: $sidebar-mini-width;
        }

        .sidebar{
            display: block;
            font-weight: 200;
            z-index: 9999;

            .logo{
                a.logo-normal{
                    opacity: 0;
                    @include transform-translate-x(-25px);
                }
            }

            .sidebar-wrapper{
                > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info > a > span,
                > .nav li > a p{
                    @include transform-translate-x(-25px);
                    opacity: 0;
                }
            }
        }

        .sidebar:hover{
            width: 260px;

            .navbar-minimize {
              opacity: 1;
            }

            .logo{
                a.logo-normal{
                    opacity: 1;
                    @include transform-translate-x(0px);
                }
            }

            .sidebar-wrapper{
                width: 260px;

                > .nav li > a p,
                > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
                .user .user-info > a > span{
                    @include transform-translate-x(0px);
                    opacity: 1;
                }
            }
        }
    }
}
