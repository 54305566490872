.md-datepicker-body-header{
  .md-icon-button{
    background-color: transparent !important;
    box-shadow: none !important;
    font-weight: 500;

    &:hover,
    &:focus{
      background-color: #eee !important;
    }

    .md-button-content svg{
      fill: #212529 !important;
    }
  }
}

.md-datepicker-header .md-datepicker-date-select{
  font-size: 30px;
}

.md-button[class*="md-datepicker-"]{
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 500;
  border-radius: 3px;
  margin-left: 48px;
  margin-right: 48px;

  &:hover,
  &:focus{
    background-color: #eee !important;
  }

  .md-button-content{
    color: #212529 !important;
  }
}

.md-datepicker-day-button{
  font-size: 0.875rem;

  &:hover,
  &:focus{
    background-color: #eee !important;
  }
}

.md-datepicker-today,
.md-datepicker-selected{
  @include shadow-2dp-color($brand-primary);
  font-weight: lighter !important;

  &,
  &:hover,
  &:focus{
    background-color: $brand-primary !important;
    color: $white-color !important;
  }
}

.md-datepicker-month-button,
.md-datepicker-year-button{
  font-weight: 300;
}

.md-datepicker-dayname,
.md-datepicker-monthname,
.md-datepicker-day{
  font-weight: 300 !important;
}

.md-datepicker-dialog .md-datepicker-header{
  background: linear-gradient(60deg, $purple-400, $purple-600) !important;
}

.md-datepicker-overlay,
.md-datepicker-dialog {
  z-index: 9999;
}

.md-datepicker-dialog .md-datepicker-body-content {
  height: 236px !important;
}
