.md-chip {
  height: 21px;
  line-height: 21px;
  background-color: $gray-color !important;
  color: $white-color !important;
  margin-top: 7px;

  .md-ripple {
    font-weight: 500;
    text-transform: uppercase;
    font-size: .80em;
    padding: 0 12px !important;
    -webkit-transition: all 150ms ease 0s;
    -moz-transition: all 150ms ease 0s;
    -o-transition: all 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s;
  }

  &:hover {
    .md-ripple {
      padding-right: 21px !important;
    }

    .md-input-action .md-button-content i svg {
      opacity: 1;
    }

    .md-input-action{
      background-color: transparent !important;
    }
  }

  .md-input-action {
    background-color: transparent !important;
    box-shadow: none !important;
    top: 49% !important;

    .md-button-content i svg {
      fill: $white-color !important;
      opacity: 0;
      -webkit-transition: opacity 150ms ease 0s;
      -moz-transition: opacity 150ms ease 0s;
      -o-transition: opacity 150ms ease 0s;
      -ms-transition: opacity 150ms ease 0s;
      transition: opacity 150ms ease 0s;
    }
  }
}

.md-chips:before,
.md-chips:after{
  display: none;
}

.md-chips{
  &.md-primary .md-chip{
    background-color: $brand-primary !important;
  }
  &.md-info .md-chip{
    background-color: $brand-info !important;
  }
  &.md-warning .md-chip{
    background-color: $brand-warning !important;
  }
  &.md-rose .md-chip{
    background-color: $brand-rose !important;
  }
  &.md-danger .md-chip{
    background-color: $brand-danger !important;
  }
  &.md-success .md-chip{
    background-color: $brand-success !important;
  }
}
