.md-layout,
.md-layout-item{
  width: 100%;
}

.md-layout-item{
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }
    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
    .sidebar .nav>li.active-pro {
        position: absolute;
        width: 100%;
        bottom: 10px;
    }
}

#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
}

.places-buttons .btn {
    margin-bottom: 30px
}

.space-70 {
    height: 70px;
    display: block;
}

.tim-row {
    margin-bottom: 20px;
}

.tim-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
}

.tim-typo .tim-note {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}

.tim-row {
    padding-top: 50px;
}

.tim-row h3 {
    margin-top: 0;
}

.md-offset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto !important;
}

@media (min-width: 576px) {
  .md-offset {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .md-offset {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .md-offset {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .md-offset {
      max-width: 1140px;
  }
}
