body {
  background-color: #EEEEEE;
  letter-spacing: unset;
  // background: #fdfdfe;
  color: $black-color;
  &.inverse {
    background: #333333;
    &, .form-control {
      color: $mdb-text-color-light;
    }
    .modal,
    .panel-default,
    .card {
      &,
      .form-control {
        background-color: initial;
        color: initial;
      }
    }

  }
}

html *{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.wrapper{
  position: relative;
  top: 0;
  height: 100vh;
  
    &.wrapper-full-page{
        height: auto;
        min-height: 100vh;
    }
}


blockquote{
    p{
        font-style: italic;
    }
}

.life-of-material-dashboard{
    background: #FFFFFF;
}


.serif-font{
    font-family: $font-family-serif;
}

a{
  color: $link-color;
  &:hover,
  &:focus{
      color: darken($link-color, 5%) !important;
      text-decoration: none !important;
  }

  &.text-info{
      &:hover, &:focus{
          color: darken($brand-info, 5%);
      }
  }

  & .material-icons {
    vertical-align: middle;
  }
}

a[data-toggle="collapse"][aria-expanded="true"] .caret,
.md-focused .md-select  .md-icon.md-icon-image svg{
    @include rotate-180();
}

.sidebar a,
.caret,
.md-select .md-icon.md-icon-image svg,
.md-collapse .md-icon,
.sidebar .dropdown .dropdown-menu li a{
    @include transition($fast-transition-time, $transition-ease-in);
}

/*           Animations              */
.animation-transition-general{
    @include transition($general-transition-time, $transition-linear);
}

.animation-transition-slow{
    @include transition($slow-transition-time, $transition-linear);
}

.animation-transition-fast{
    @include transition($fast-transition-time, $transition-ease);
}
legend {
  border-bottom: 0;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}
a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline : 0 !important;
}

.text-center{
  justify-content: center;
}
.text-left{
  justify-content: flex-start;
}
.text-right{
  justify-content: flex-end;
}

.places-buttons{
  .md-button{
    width: 30%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.md-theme-default :not(input):not(textarea)::selection{
  background-color: #c8c8c8 !important;
}

#worldMap{
  width: 100%;
}

.map{
  height: 300px;
}

h1, h2, h3,
h4, h5, h6 {
    @extend %headings;
}

.md-ripple span.md-ripple-wave{
  border-radius: 50% !important;
}

.md-ripple{
  -webkit-mask-image: none;
  overflow: visible;

  > span{
    overflow: hidden;
    position:absolute;
    top:0;
    left:0;
    width:100%;
  }
}

.md-toolbar .md-list .md-list-item-content.md-ripple {
  overflow: hidden;
}

.md-icon.md-theme-default.md-icon-image svg {
  fill: $black-color;
}

.black-color{
  color: $black-color !important;
}

.mt-4{
  margin-top: 1.5rem!important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto!important;
}

small,
.small{
  font-size: 80%;
  font-weight: 400;
}
