.md-card-tabs{
  .md-list{
    flex-flow: initial;
    padding: 0 15px;
  }

  &.page-subcategories > .md-card-header .title{
    margin-top: 30px !important;
    margin-bottom: 25px !important;
    min-height: 32px;
  }

  .tab-content{
    padding: 20px 0 9px;
  }

  .md-list-item + .md-list-item{
    margin-left: 5px;
  }

  .md-list .md-list-item .md-list-item-button{
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    color: $black-color !important;
    transition: all .3s;
    border-radius: 30px;
    padding: 0;

    .md-icon{
      display: flex;
      width: auto;
      height: auto;
      margin: 0;
      font-size: 30px !important;
      padding: 15px 0;

    }

    .md-list-item-content{
      min-height: auto;
      padding: 10px 15px;
      justify-content: center;
      display: flex;
    }

    &:hover,
    &:focus{
      background-color: rgba(199, 199, 199, .2) !important;
    }
  }

  .active.md-list-item .md-list-item-button{
    @include tabs-color($gray-color);
    color: $white-color !important;

    .md-list-item-content .md-icon,
    &{
      color: $white-color !important;
    }
  }

  .active.md-list-item.md-rose .md-list-item-button{
    @include tabs-color($brand-rose);
  }
  .active.md-list-item.md-primary .md-list-item-button{
    @include tabs-color($brand-primary);
  }
  .active.md-list-item.md-warning .md-list-item-button{
    @include tabs-color($brand-warning);
  }
  .active.md-list-item.md-info .md-list-item-button{
    @include tabs-color($brand-info);
  }
  .active.md-list-item.md-success .md-list-item-button{
    @include tabs-color($brand-success);
  }
  .active.md-list-item.md-danger .md-list-item-button{
    @include tabs-color($brand-danger);
  }

  &.md-card-plain {

    .md-card-header,
    .md-list{
      background-color: transparent !important;
    }

    .md-list,
    .title{
      justify-content: center;
    }
  }
}

.flex-column{
  .md-card-content{
    display: flex;
    flex-flow: initial;
  }

  .md-list{
    flex-direction: column;
    min-width: 148px;

    & .md-list-item + .md-list-item{
      margin: 0;
      margin-top: 5px;
    }
  }

  .tab-content{
    padding: 0;
    padding-left: 30px;
  }
}

.nav-pills-icons{
  .md-list .md-list-item .md-list-item-button {
    border-radius: 4px;

    .md-list-item-content{
      flex-direction: column-reverse;
    }
  }
}
